<template>
  <view-container name="login">
    <view-content>
      <div data-vue-name="Authorization" class="scroll-hide authorization">
        <div class="container-fluid">
          <div class="authorization__title">Welcome</div>
          <div v-if='registered' class='authorization__registered'>Check your email to verify</div>
          <div v-if='isShowMessageVerify' class='authorization__registered'>You must verify your email</div>
          <div v-if='isWrongCredentials' class='authorization__error'>You cannot sign with those credentials</div>

          <form class="authorization__form" @submit.prevent="handleSubmit">
            <AuthInput
              v-model="user.email"
              :valid="isValidMail"
              :placeholder="'Your Email'"
              :error="showEmailError && !isValidMail"
              :errorText="passErrorMsg || emailErrorMessageFront || ''"
            />

            <AuthInput
              v-model="user.password"
              :type="'password'"
              :placeholder="'Password'"
              :error="(passErrorMsg || showPassError) && !isValidPass"
              :errorText="passErrorMsg || passErrorMessageFront || ''"
            />

            <div class="authorization__stroke">
              <router-link class="authorization__btn-style-link" to="/forgot-password">Forgot Password?</router-link>
            </div>

            <CommonButton :type="'submit'" :caption="'Sign in'" />

            <div>
              New user?
              <router-link class="authorization__btn-style-link" to="/registration">Join Competa</router-link>
            </div>
          </form>

          <div class="authorization__social">
            <CommonButton
              @clicked="handleGoogleSubmit"
              :caption="'Sign in with Google'"
              :variant="'social'"
              :is-disabled="disableSocial"
            >
              <svg class="ico-google">
                <use v-if="disableSocial" xlink:href="../assets/img/svg-sprite.svg#icoGoogleDisable"></use>
                <use v-else xlink:href="../assets/img/svg-sprite.svg#icoGoogle"></use>
              </svg>
            </CommonButton>

          </div>
        </div>
      </div>
    </view-content>
  </view-container>
</template>

<script>
import { mapState } from 'vuex'
import eventBus from '@/plugins/eventBus'
import AuthInput from '@/components/UI/AuthInput'
import CommonButton from '@/components/UI/CommonButton'

export default {
  name: 'Authorization',
  components: { AuthInput, CommonButton },
  computed: {
    ...mapState({
      errors: ({ auth }) => auth.errors,
      invitationId: ({ eventHash }) => eventHash.id
    }),
    isPasswordType() {
      return this.passwordInputType === 'password';
    },
    isValidMail() {
      return this.validEmail(this.user.email);
    },
    isValidPass() {
      return this.user.password.length >= 6;
    },
    passErrorMsg() {
      return this.errors?.password
    },
    emailErrorMsg() {
      return this.errors?.email
    },
    disableSocial() {
      return false;
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      passwordInputType: 'password',
      registered: false,
      isShowMessageVerify: false,
      isWrongCredentials: false,
      showPassError: false,
      showEmailError: false,
      passErrorMessageFront: 'Password must contain at least 6 characters',
      emailErrorMessageFront: 'Incorrect email address'
    }
  },
  mounted() {
    eventBus.$on('registered', () => {
      this.registered = true
    })
  },
  methods: {
    switchVisibility() {
      this.passwordInputType = this.isPasswordType ? 'text' : 'password'
    },
    handleSubmit() {
      this.isShowMessageVerify = false;
      this.showPassError = false;
      this.showEmailError = false;

      if (!this.isValidMail || !this.isValidPass) {
        if (!this.isValidPass) {
          this.showPassError = true;
        }
        if (!this.isValidMail) {
          this.showEmailError = true;
        }
        return;
      }

      this.$store.dispatch('login', this.user).then(() => {
        return this.$store.dispatch('getUser').then(() => {
          if (this.hash) {
            return this.$router.push({ name: 'competitions' })
          } else if( this.invitationId ) {
            return this.$router.push({ name: 'personalInfo' })
          } else {
            return this.$router.push({ name: 'account' })
          }
        })

      }).catch((Error) => {
        if (Error?.response?.data?.message==='You must verify your email') {
          this.isShowMessageVerify = true
        } else if (Error?.response?.data?.message==='You cannot sign with those credentials') {
          this.isWrongCredentials = true
        } else if (Error?.response?.data?.message==='The given data was invalid.') {
          this.isWrongCredentials = true
        }
      })
    },
    handleGoogleSubmit() {
      this.$store.dispatch('loginWithGoogle').then(res => {
        console.log('res ->', res) // eslint-disable-line no-console
        window.location = res.redirect
      })
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
  },
}
</script>

<style lang="stylus">

.login-view {
  .view-content {
    padding 0 !important
  }
}

.authorization {
  height 100%
  width 100%
  background url("../assets/img/bg-page-auth-1.png") no-repeat
  background-size cover
  background-position: center;
  display flex
  flex-direction column
}

.authorization__title {
  font-family: 'Poppins', sans-serif;
  font-size 36px
  font-weight: 600;
  line-height: 122%;
  color: #EEEEEE;
  margin-top 96px
  margin-bottom 16px
}

.authorization__form {
  width 100%
  margin-bottom 80px
}

.authorization__form > div {
  position relative
  margin-top 16px
  color #eee
}

.authorization__form .btn-style1 {
  height 48px
  margin-bottom 16px
}

.authorization__btn-style-link {
  color: #6293FF;
  margin-top 4px
  text-decoration none
}

.authorization__social {
  margin-bottom 75px
}

.authorization__social > div {
  margin-bottom 13px
}

.authorization__registered {
  margin-bottom: 25px;
  font-weight: 700;
}
.authorization__error {
  margin-bottom: 25px;
  font-weight: 700;
  color: red;
}

.authorization__stroke {
  margin-bottom: 40px;
}
</style>
